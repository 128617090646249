const $body = $('html, body');
const $wrapper = $('.product-catalog-group');
const $default = $('.product-catalog', $wrapper).prop('outerHTML');
let ajax, timeout;

$wrapper.on('click', '.sort-pagination__filter, .filter-close', () => $body.toggleClass('show-sidebar'));
$wrapper.on('click', '#filter-button, .apply-filters, .close-button', () => $body.removeClass('show-sidebar'));
$(window).on('resize', ({currentTarget: element}) => $(element).width() > 1024 && $body.removeClass('show-sidebar'));

$wrapper.on('init change', '.woocommerce-ordering select', ({currentTarget: element}) => {
  $(element).select2({minimumResultsForSearch: Infinity}).on('select2:open', () => $('.select2-dropdown').addClass('max-content'));
  const text = $(element).next().text();
  $('.woocommerce-ordering .select2-selection__rendered').each((i, el) => {
    $(el).html(`<span>${text.replace(/(sort by )?(.+)/i, '$1<b>$2</b>')}</span>`);
  });
});

$('.woocommerce-ordering select', $wrapper).trigger('init');

$wrapper.on('change submit', '.woocommerce-ordering', event => {
  event.preventDefault();
  const $filter = $('.filter', $wrapper);
  $(event.currentTarget).serializeArray().forEach(({name, value}) => {
    $filter.append(`<input type="hidden" name="${name}" value="${value}">`);
  });
  $filter.trigger('submit');
});

$wrapper.on('input change', 'input[name="min_price"], input[name="max_price"]', ({currentTarget: element}) => {
  ajax && ajax.abort();
  const group = element.closest('.filter_group');
  const min = group.querySelector('input[name="min_price"]').value;
  const max = group.querySelector('input[name="max_price"]').value;
  $(':radio', group).prop('checked', false);
  $(`:radio[data-min="${min}"][data-max="${max}"]`, group).prop('checked', true);
  $wrapper.trigger('recount');
});

$wrapper.on('change', 'input[name="price_preset"]', ({currentTarget: element}) => {
  const group = element.closest('.filter_group');
  group.querySelector('input[name="min_price"]').value = element.dataset.min;
  group.querySelector('input[name="max_price"]').value = element.dataset.max;
});

$wrapper.on('change', ':radio, :checkbox', ({currentTarget: target}) => {
  const group = target.closest('.filter_group');
  const {name, queryType} = group.dataset;
  if (name) {
    const values = [...group.querySelectorAll(':checked')].map(x => x.dataset.value);
    group.querySelector(`input[name="filter_${name}"]`).value = values.join(',');
    if (queryType) group.querySelector(`input[name="query_type_${name}"]`).value = values.length ? queryType : '';
  }
  if ($(window).width() > 1024) {
    ajax && ajax.abort();
    clearTimeout(timeout);
    timeout = setTimeout(() => $(target).trigger('submit'), 1000);
  }
  $wrapper.trigger('recount');
});

$wrapper.on('submit', '.filter', event => {
  event.preventDefault();
  const $form = $(event.currentTarget);
  const data = $form.serializeArray().filter(x => x.value !== '').reduce((obj, item) => ({...obj, [item.name]: item.value}), {});
  const search = Object.entries(data).map(([name, value]) => `${name}=${value}`).join('&');
  const url = $form.attr('action') + (search ? `?${search}` : '');
  $('#main', $wrapper).addClass('loading');
  ajax && ajax.abort();
  ajax = $.ajax({
    url, success: html => {
      const $data = $('.product-catalog', html);
      $('.product-catalog', $wrapper).replaceWith($data);
      $('.woocommerce-ordering select', $wrapper).trigger('init');
      history.pushState({'urlPath': url, 'html': $data.prop('outerHTML'), 'title': document.title}, '', url);
      $wrapper.trigger('recount');
    },
  });
});

$wrapper.on(' recount', event => {
  // Select all checked radio and checkboxes that don't have the name price_preset
  let count = $(':radio:checked, :checkbox:checked', $wrapper).not('[name=price_preset]').length;
  count += $('[name=min_price]', $wrapper).val() > 0 ? 1 : 0;
  count += $('[name=max_price]', $wrapper).val() > 0 ? 1 : 0;
  count += $('[name^="size"]', $wrapper).val() ? 1 : 0;
  $('.filter-count', $wrapper).text(count);
}).trigger('recount');

$(window).on('popstate', ({originalEvent: e}) => {
  if ($wrapper.length > 0) {
    const $html = $(e.state?.html);
    if ($html.is('.product-catalog')) {
      $('.product-catalog', $wrapper).replaceWith($html);
    } else {
      $('.product-catalog', $wrapper).replaceWith($default);
      $('.woocommerce-ordering select', $wrapper).trigger('init');
    }
    e.preventDefault();
  }
});

$wrapper.on('click', '.remove-filter', ({currentTarget: element}) => {
  const [name, value] = [element.parentElement.dataset.name, element.dataset.value];
  if (name === 'price') {
    $('input[name="min_price"], input[name="max_price"]', $wrapper).val('');
    $('input[name="price_preset"]', $wrapper).prop('checked', false);
    $(element.parentElement).slideUp().trigger('submit');
  } else if (name === 'size') {
    $('input[name^="size"]', $wrapper).remove();
    $(element.parentElement).slideUp().trigger('submit');
  } else {
    $(`input[data-name="${name}"][data-value="${value}"]`, $wrapper).prop('checked', false).trigger('change');
    $(`input[data-name="${name}"]:checked`, $wrapper).length ? $(element).slideUp() : $(element.parentElement).slideUp();
  }
  $wrapper.trigger('recount');
});

$(document).on('click', ({target}) => {
  if ($(target).hasClass('tooltip-icon')) {
    const $tooltipIcon = $(target);
    const $container = $tooltipIcon.parent().parent();
    const $tooltip = $container.find('.tooltip');
    const tooltipPosition = $tooltipIcon.position().left + $tooltipIcon.outerWidth();
    $tooltip.css({position: 'absolute', left: tooltipPosition + 10});
    $tooltip.fadeToggle();
  } else {
    $('.tooltip', $wrapper).fadeOut();
  }
});

$wrapper.on('click', '.filter_title', ({currentTarget: element, target}) => {
  const $group = $(element.parentElement);
  if (!$(target).hasClass('tooltip-icon')) {
    $group.find('.filter_options').slideToggle();
  }
  if ($(target).hasClass('filter_title')) {
    $group.toggleClass('arrow-rotate');
  } else if ($(target).is(':header')) {
    $group.toggleClass('arrow-rotate');
  }
});
