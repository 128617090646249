import Swiper from 'swiper';
import {Autoplay, EffectCreative, Navigation, Pagination} from 'swiper/modules';

Swiper.use([Pagination, Navigation, EffectCreative, Autoplay]);

const brandsSlider = new Swiper('.brands-slider', {
  loop: true,
  freeMode: true,
  slidesPerView: 'auto',
  speed: 11000,
  autoplay: {
    delay: 0,
    pauseOnMouseEnter: false,
    disableOnInteraction: false,
    waitForTransition: true,
    stopOnLastSlide: false,
  },
});

const clear_input = $('.clear-input')
$('.brand-search-input').on('input', function() {
  const searchText = $(this).val().toLowerCase();
  let found = false;
  clear_input.hide()

  $('.logo').each(function() {
    let brandName = $(this).data('name').toLowerCase();
    if (brandName.includes(searchText)) {
      $(this).show();
      found = true
    } else {
      $(this).hide();
    }
  });
  if (!found) {
    $('.no-results-message').show();
    clear_input.show()
  } else {
    $('.no-results-message').hide();
  }
});

clear_input.on('click', function() {
  $('.brand-search-input').val('');
  $('.logo').show();
  $('.no-results-message').hide();
  $(this).hide();
});


