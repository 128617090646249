const $hero_video = $('.hero_video video');
$hero_video.attr('src', $hero_video.data('src'));

$(document).on('click', '.quantity-stepper', (event) => {
  const $this = $(event.currentTarget);
  const $input = $this.parent().find('input');
  const increment = Number($this.data('increment'));

  // We have to get min and max on click bc these values change on variation select
  const min = Number($input.attr('min'));
  const max = Number($input.attr('max'));
  const step = Number($input.attr('step'));

  const initialValue = Number($input.val());

  if (max) {
    $input.val(Math.min(max, Math.max(min, initialValue + (step * increment))));
  } else {
    $input.val(Math.max(min, initialValue + (step * increment)));
  }

  $input.trigger('change');
});

$(document).on('click', '.single_add_to_cart_button', () => {
  // Fix stupid ajax add to cart plugin not ignoring disabled inputs
  $.fn.serializeArrayAll = $.fn.serializeArray;
});

$(document).on('click', '.oversized', function () {
  $(this).find('.oversized-tooltip').toggleClass('active');
});

$(document).on('click', '.iv-comment-image a', function (e) {
  const {photoswipe_options: options, photoswipe_enabled: enabled} = wc_single_product_params;
  if (typeof PhotoSwipe !== 'undefined' && enabled) {
    e.preventDefault();
    const pswp = $('.pswp')[0];
    const $img = $(this).find('img');
    new PhotoSwipe(pswp, PhotoSwipeUI_Default, [{src: $img.attr('src'), w: $img.attr('width'), h: $img.attr('height')}], options).init();
  }
});
