import Swiper from 'swiper';
import {Autoplay, Mousewheel, Navigation, Pagination, Scrollbar} from 'swiper/modules';

Swiper.use([Autoplay, Mousewheel, Navigation, Pagination, Scrollbar]);

document.querySelectorAll('.wp-block-handpicked-products').forEach(el => {
  const controls = document.createElement('div');
  new Swiper(el, {
    createElements: true,
    wrapperClass: 'wc-block-grid__products',
    slideClass: 'wc-block-grid__product',
    slidesPerView: 'auto',
    navigation: {
      enabled: true,
    },
    mousewheel: {
      forceToAxis: true,
    },
  });
});
