const $input = $('input, textarea');
const notice = '<p class="california-notice">Prop 65: This website sells products that contain chemicals known to the State of California to cause cancer and birth defects or other reproductive harm.</p>';
let $billing_state = $('#billing_state')
let $billing_state_field = $('#billing_state_field')

$input.on('focus', function () {
  $(this).closest('.gfield').find('label').addClass('away');

  console.log($(this).attr('type'));
  console.log($(this).val().length);
});

$input.on('blur', function () {
  if (!$(this).val()) {
    $(this).closest('.gfield').find('label').removeClass('away');
  }
});

$('input[type="file"]').on('focus', function () {
  $(this).closest('.gfield').find('label').addClass('focus');
});
$('input[type="file"]').on('blur', function () {
  $(this).closest('.gfield').find('label').removeClass('focus');
});

$('footer .mailing-list-submit').on('click', ()=> {
  $('.gform_footer .gform_button').click()
})

$(document).on('select2:open', 'select', function() {
  $('.select2-search--dropdown .select2-search__field').attr('placeholder', 'Type to search…');
});

$(document).on('select2:select', '#billing_state', function(e) {
  $('#billing_state_field .california-notice').remove();
  if (e.params.data.text === 'California') {
    $billing_state_field.append(notice);
  }
});

if($billing_state.val() === 'CA') {
  $billing_state_field.append(notice);
}
