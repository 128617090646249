import Swiper from 'swiper';
import {Autoplay, Mousewheel, Navigation, Pagination, Scrollbar} from 'swiper/modules';

Swiper.use([Autoplay, Mousewheel, Navigation, Pagination, Scrollbar]);

document.querySelectorAll('.wc-prl-recommendations').forEach(el => {
  const controls = document.createElement('div');
  new Swiper(el, {
    createElements: true,
    wrapperClass: 'products',
    slideClass: 'product',
    slidesPerView: 'auto',
    navigation: {
      enabled: true,
    },
    mousewheel: {
      forceToAxis: true,
    },
    pagination: {
      enabled: true,
      clickable: true,
      type: 'custom',
      renderCustom: function (swiper, current, total) {
        return `<span class="swiper-number">${current}</span> OF <span class="swiper-number">${total}</span>`;
      },
    },
    on: {
      init: swiper => {
        controls.classList.add('swiper-controls');
        controls.append(swiper.navigation.prevEl, swiper.pagination.el, swiper.navigation.nextEl);
        swiper.el.insertBefore(controls, swiper.wrapperEl);
      },
      lock: swiper => {
        controls.classList.add('swiper-controls-lock');
      },
      unlock: swiper => {
        controls.classList.remove('swiper-controls-lock');
      },
    },
  });
});
