const $filterModal = $('.filter-block-modal');
const $tooltip = $('.reset-selections-tooltip');
const Money = Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

class Variation {
  constructor($form) {
    this.$form = $form;

    $form.on('found_variation', this.#found_variation.bind(this));
    $form.on('hide_variation', this.#hide_variation.bind(this));
    $form.on('check_variations', this.#check_variations.bind(this));
    $form.on('change', this.#calculate_subtotal.bind(this));

    $form.find('select:visible').select2({width: 'style'}).on('select2:open', this.#remove_tire_size_footer.bind(this).bind(this));
    if ($filterModal.length) $form.find('#pa_full-size, #pa_full-chain-size').on('select2:open', this.#add_tire_size_footer.bind(this).bind(this));

    const $wrap = $form.parents().eq(2);
    this.$price = $wrap.find('.wc-block-components-product-price, .price');
    this.$stock = $wrap.find('.stock_wrap');
    this.$desc = $wrap.find('.woocommerce-variation-description');
    this.$sku = $wrap.find('.sku');
    this.$oversize = $wrap.find('.oversized');
    this.$table = $('.product-table');
    this.$subtotal = $wrap.find('.woocommerce-variation-subtotal');

    this.price_html = this.$price.html();
    this.stock_html = this.$stock.html();
    this.table_html = this.$table.html();
  }

  static init(event) {
    let $form = $('.variations_form:last');
    if (event?.handleObj.type === 'woosq_loaded') {
      $form = $('.woosq-popup .variations_form');
    }

    if ($form.length !== 0) new Variation($form);
  }

  #found_variation(event, variation) {
    this.current_variation = variation;
    this.$price?.html(variation.price_html || this.price_html);
    this.$stock?.html(variation.availability_html || this.stock_html);
    if (this.$sku.siblings().length) {
      this.$sku?.html(variation.sku).show();
    } else {
      variation.sku ? this.$sku?.html(variation.sku).slideDown() : this.$sku?.slideUp(400, () => this.$sku?.html(''));
    }
    this.$table?.html(variation.table_html || this.table_html);
    variation.oversized ? this.$oversize?.slideDown() : this.$oversize?.slideUp();
    variation.variation_description ? this.$desc?.html(variation.variation_description).slideDown() : this.$desc?.slideUp(400, () => this.$desc?.html(''));
    if (variation.weight_html !== 'N/A') {
      this.$table?.find('tbody').append($('<tr class="weight">').append($('<th>').text('Weight'), $('<td>').html(variation.weight_html)));
    }
    if (variation.dimensions_html !== 'N/A') {
      this.$table?.find('tbody').append($('<tr class="dimensions">').append($('<th>').text('Dimensions'), $('<td>').html(variation.dimensions_html)));
    }
    this.#calculate_subtotal();
  }

  #hide_variation() {
    this.current_variation = null;
    this.$price?.html(this.price_html);
    this.$stock?.html(this.stock_html);
    this.$table?.html(this.table_html);
    this.$sku.siblings().length ? this.$sku?.html('') : this.$sku?.slideUp(400, () => this.$desc?.html(''));
    this.$desc?.slideUp(400, () => this.$desc?.html(''));
    this.$oversize?.slideUp();
    this.#calculate_subtotal();
  }

  #check_variations(event) {
    const $form = $(event.currentTarget);
    const $attributeFields = $form.find('.variations select');
    const variationAttributes = $form.data('product_attributes');
    if (variationAttributes) {
      let attributes = {};
      $attributeFields.each(function () {
        const attribute_name = $(this).data('attribute_name') || $(this).attr('name');
        attributes[attribute_name] = $(this).val() || '';
      });
      $attributeFields.filter('.woo-variation-raw-select').each(function () {
        const attribute_name = $(this).data('attribute_name') || $(this).attr('name');
        const $swatchField = $form.find(`ul[data-attribute_name="${attribute_name}"]`);
        $(this).find('option:not(:first-child)').each(function () {
          const variation = {...attributes, [attribute_name]: this.value};
          const found = variationAttributes.filter(variation1 => {
            for (const [attribute_name1, val1] of Object.entries(variation1)) {
              const val2 = variation[attribute_name1];
              if (val1 !== undefined && val2 !== undefined && val1.length !== 0 && val2.length !== 0 && val1 !== val2) {
                return false;
              }
            }
            return true;
          });
          const disable = found.length === 0;
          //console.info(attribute_name, this.value, !disable, found)
          this.disabled = disable;
          if (disable) {
            $(this).find(`option[value="${this.value}"]`).prop('selected', false);
            setTimeout(() => {
              $swatchField.find(`li[data-value="${this.value}"]`)
                .addClass('disabled')
                .removeClass('selected')
                .attr('aria-checked', 'false')
                .attr('tabindex', '-1');
            });
          }
        });
      });
    }

    setTimeout(() => {
      if (this.run_event_once) return;
      this.run_event_once = true;
      $('.woo-variation-items-wrapper').each((i, element) => {
        const $item = $(element).find('.variable-item:not(.disabled)');
        if ($item.length === 1 && !$item.hasClass('selected')) $item.click();
      });
      this.run_event_once = false;
    });
  }

  #remove_tire_size_footer() {
    $('.select2-results__footer').remove();
  }

  #add_tire_size_footer() {
    if ($('.select2-results__footer').length) return;
    $('.select2-results').append(`<a class="select2-results__footer">Can't find your size? Click here to search all available sizes</a>`);
  }

  #calculate_subtotal() {
    let subtotal = this.current_variation?.display_price * this.$form.find('[name=quantity]').val();
    const $tensioner = this.$form.find('.tensioner_radio[data-price]:has(:checked)');
    if ($tensioner.length) {
      subtotal += $tensioner.data('price') * $tensioner.find('[name=tensioner_quantity]').val();
    }
    const subtotal_html = subtotal > 0 ? '<label>Subtotal:</label>' + Money.format(subtotal) : null;
    subtotal_html ? this.$subtotal?.html(subtotal_html).slideDown() : this.$subtotal?.slideUp(400, () => this.$subtotal?.html(''));
  }
}

Variation.init();
$(document.body).on('woosq_loaded', Variation.init);

$(document).on('change', '.variations_form .variations select', event => {
  $(event.target).removeClass('required').next().removeClass('required');
});

$(document).on('click', '.variations_form .single_add_to_cart_button', event => {
  const $this = $(event.target);
  const $form = $this.closest('form.variations_form');
  if ($this.is('.wc-variation-selection-needed')) {
    $form.find('select').each((i, select) => {
      const $select = $(select);
      if (!$select.val()) $select.addClass('required').next().addClass('required');
    });
  }
});

$(document).on('mouseenter', '.variable-item', event => {
  const $this = $(event.currentTarget);
  if ($this.hasClass('disabled')) {
    const windowWidth = $(window).width();
    const buttonPosition = $this.offset();
    const tooltipHeight = $tooltip.outerHeight();
    const tooltipWidth = $tooltip.outerWidth();
    const tooltipTop = buttonPosition.top + tooltipHeight - 13;
    let tooltipLeft = buttonPosition.left - 10;

    if (tooltipLeft < 0) tooltipLeft = 0;
    if (tooltipLeft + tooltipWidth > windowWidth) tooltipLeft = (windowWidth - tooltipWidth) - 10;

    $tooltip.data('$form', $this.closest('form.variations_form'));
    $tooltip.css({left: tooltipLeft, top: tooltipTop}).show();
    $this.mouseleave(() => $tooltip.hide());
  }
});

$(document).on('mouseleave', '.variable-item', () => {
  $tooltip.hover(
    event => $(event.currentTarget).stop().show(),
    event => $(event.currentTarget).stop().hide(),
  );
});

$tooltip.find('*').on('click', event => {
  const $form = $tooltip.data('$form');
  $form.find('.variations select').val('').trigger('change');
  $form.trigger('reset_data');
  $tooltip.hide();
});

$('.variable-item').on('click', function (event) {
  if ($(this).hasClass('disabled')) {
    event.stopPropagation();
  }
});

$(document.body).on('woosq_loaded', event => {
  $('.woosq-popup .variable-item').on('click', function (event) {
    if ($(this).hasClass('disabled')) {
      event.stopPropagation();
    }
  });
});

$(document).on('click', '.select2-results__footer', event => {
  event.preventDefault();
  $('#pa_full-size, #pa_full-chain-size').select2('close');
  $('html').css('overflow', 'hidden').css('margin-right', $.scrollbarWidth());
  $filterModal.fadeIn('fast');
});
