const $faqContainer = $('.faq_container');
const $faqsTabsCard = $('.faqs_tabs__card');
const $faqsDetailsCard = $('.faqs_details__card');

$faqContainer.on('click', function () {
  // Remove 'mini-open' class from all faq_container divs
  $faqContainer.removeClass('mini-open');

  // Add 'mini-open' class to the clicked faq_container div
  $(this).addClass('mini-open');
});

$faqsTabsCard.click(function () {
  const tabId = $(this).data('tab');
  showTab(tabId);
});

showTab('tab0');

function showTab(tabId) {
  $faqsDetailsCard.hide();
  $faqsTabsCard.removeClass('active');
  $(`#${tabId}`).show();
  $(`[data-tab="${tabId}"]`).addClass('active');
}
