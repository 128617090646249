const $sizeFilter = $('#size-filter');
if ($sizeFilter.length) fetch(`/?wc-ajax=size_filter&parent=${$sizeFilter.data('parent')}`)
  .then(response => response.json()).then(init)
  .catch(error => console.error('Fetch Error:', error));

function init(woocommerce_filter) {
  //const $tireInfo = $('.filter-block-help > *');
  const $sizeOne = $sizeFilter.find('[name=size1]').select2({width: 'style'});
  const $sizeTwo = $sizeFilter.find('[name=size2');
  const $sizeThree = $sizeFilter.find('[name=size3');
  const $category = $sizeFilter.find('.category');
  const $submit = $sizeFilter.find('#submit');

  const sizes = woocommerce_filter['sizes'];
  const categories = woocommerce_filter['categories'];
  const searchParams = new URLSearchParams(location.search);
  const pathname = location.pathname.replace(/\/+$/, '').substring(1);

  Object.keys(sizes)
    .sort((a, b) => a.localeCompare(b, 'en', {numeric: true}))
    .forEach(key => $sizeOne.append(`<option value="${key}">${key}</option>`));

  $sizeOne.on('change', event => {
    $sizeTwo.empty().append(`<option value="">Select A Size 2</option>`).attr('disabled', true);
    if ($sizeTwo.data('select2')) $sizeTwo.select2('destroy');
    $sizeThree.empty().append(`<option value="">Select A Size 3</option>`).attr('disabled', true);
    if ($sizeThree.data('select2')) $sizeThree.select2('destroy');
    $category.empty().append(`<option value="">Select a Vehicle Type</option>`).attr('disabled', true).change();
    if ($category.data('select2')) $category.select2('destroy');
    const sizeOptions = sizes[$sizeOne.val()];

    const size2 = Object.entries(sizeOptions['size2'] ?? []);
    const rim_size = Object.entries(sizeOptions['rim_size'] ?? []);
    const options = [
      ...size2.map(([size2, size3]) => `<option data-rim_size="${Object.keys(size3)}" value="${size2}">${size2}</option>`),
      ...rim_size.map(([size3, category]) => `<option data-category="${category}" value="${size3}">${size3}</option>`),
    ].sort((a, b) => $(a).val() - $(b).val());
    $sizeTwo.append(options).attr('disabled', false).select2({width: 'style', minimumResultsForSearch: 10});
  });

  $sizeTwo.on('change', event => {
    $sizeThree.empty().append(`<option value="">Select A Size 3</option>`).attr('disabled', true);
    if ($sizeThree.data('select2')) $sizeThree.select2('destroy');
    $category.empty().append(`<option value="">Select a Vehicle Type</option>`).attr('disabled', true).change();
    if ($category.data('select2')) $category.select2('destroy');
    const $selected = $sizeTwo.find(':selected');
    const rim_size = $selected.data('rim_size')?.toString().split(',');
    const categories = $selected.data('category')?.toString().split(',');
    if (rim_size) {
      const options = rim_size.map(size3 => `<option data-category="${sizes[$sizeOne.val()]['size2'][$sizeTwo.val()][size3]}" value="${size3}">${size3}</option>`);
      $sizeThree.append(options).attr('disabled', false).select2({width: 'style', minimumResultsForSearch: 10});
    }
    if (categories) {
      categoryOptions(categories);
    }
  });

  $sizeThree.on('change', event => {
    $category.empty().append(`<option value="">Select a Vehicle Type</option>`).attr('disabled', true).change();
    const $selected = $sizeThree.find(':selected');
    const categories = $selected.data('category')?.toString().split(',');
    categoryOptions(categories);
  });

  function categoryOptions(array) {
    if (!array) return;
    const options = array.filter(cat => categories[cat]).map(cat => `<option data-path="${categories[cat].path}" value="${cat}">${categories[cat].name}</option>`);
    $category.append(options).attr('disabled', options.length < 2).change();
    if (options.length >= 2) $category.select2({width: 'style', minimumResultsForSearch: Infinity});
  }

  $category.on('change', event => {
    const $selected = $category.find('option').length > 2 ? $category.find(':selected[value!=""]') : $category.find(':nth-child(2)');
    if ($selected.length) {
      $sizeFilter.attr('action', `/${$selected.data('path')}/`);
      $submit.removeClass('disabled');
    } else {
      $sizeFilter.attr('action', '');
      $submit.addClass('disabled');
    }
  });

  $sizeFilter.on('change', event => $sizeFilter.parent()[0].scrollIntoView({block: 'nearest', behavior: 'smooth'}));
  $sizeFilter.on('submit', event => !$submit.hasClass('disabled') && $submit.addClass('loading'));

  if (searchParams.has('size1')) $sizeOne.val(searchParams.get('size1')).change();
  if (searchParams.has('size2')) $sizeTwo.val(searchParams.get('size2')).change();
  if (searchParams.has('size3')) $sizeThree.val(searchParams.get('size3')).change();
  if (searchParams.has('size1')) $category.val($category.find(`[data-path="${pathname}"`).val()).change();
  $sizeFilter.removeClass('loading');

}

$(document).on('click', event => {
  const $filterBlockHelp = $('.filter-block-help');
  if ($filterBlockHelp.find('*').is(event.target)) {
    $filterBlockHelp.addClass('open');
    const windowHeight = $(window).height();
    const distanceFromBottom = windowHeight - ($filterBlockHelp.offset().top - $(window).scrollTop()) - $filterBlockHelp.outerHeight();
    const isModal = $('.filter-block-modal:visible').length > 0;
    if (!isModal && distanceFromBottom < 447) {
      $('html, body').animate({scrollTop: $(window).scrollTop() + (447 - distanceFromBottom) + 20}, 1);
    }
  } else {
    $filterBlockHelp.removeClass('open');
  }
});

$(document).on('click', '.filter-block-modal, .filter-block-modal .filter-block', event => {
  if (!event.target.classList.contains('filter-block-modal') || event.target !== event.currentTarget) return;
  $('html').css('overflow', '').css('margin-right', '');
  $(event.currentTarget).fadeOut('fast');
});
